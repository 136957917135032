import React from "react"



const SSS = () => (


    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>Sosyal medya yönetimi nasıl olmalı?</h3>
            <p>Sosyal medya yönetimi; markanızın dili ve imajı ile uyumlu, hedef kitlenizin 
dikkatini çekecek, kaliteli görsel ögeler içeren, gündeme ve sosyal medya 
dinamiklerine uygun, etkileşime açık olmalı.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>Sosyal medya yönetimi neden gerekli?</h3>
            <p>Müşterilerinizle ve potansiyel müşterilerinizle buluşmak, marka algınızı 
pekiştirmek ve SEO faaliyetleri, dijital reklamlar gibi diğer çalışmaları 
desteklemek açısından sosyal medya yönetimi yapmak kritik bir öneme 
sahip.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>Sosyal medyada takipçi sayımı nasıl artırabilirim?</h3>
            <p>Sosyal medya hesaplarında takipçi sayısını organik yoldan artırabilmek 
için yaratıcı ve viral güce sahip içerikler üretebilmek ve etkili bir iletişim 
yürütmek gerekiyor.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>Hangi platformlarda sosyal medya yönetimi yapıyorsunuz?  </h3>
            <p> Öncelikli olarak Facebook, Instagram, Twitter, YouTube ve Pinterest adlı 
platformlarda sosyal medya yönetimi yapıyor, markanın konumu gereği 
gereken diğer platformları da değerlendirebiliyoruz.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    
    </div>
)

export default SSS
