import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/danismanlik-company"
import rightArrow from "../assets/img/right-arrow.png";

import maddebir from "../assets/img/dd-madde1.png"
import maddeiki from "../assets/img/dd-madde2.png"
import maddeuc from "../assets/img/dd-madde3.png"
import maddedort from "../assets/img/dd-madde4.png"
import maddebes from "../assets/img/dd-madde5.png"
import maddealti from "../assets/img/dd-madde6.png"
import digital from "../assets/img/digital-danismanlik.png"
import work from "../assets/img/work-preformance.png"
import Teams from "../components/teams/digital-team"
import SSS from "../components/sss/social-sss"
import Checkup from "../components/checkup"
import checkModal from "../assets/img/check-modal.png";


class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Dijital Pazarlama Danışmanlığı" description="Her gun değişen sosyal medya trendlerini izliyor, kitleleri etkileyecek fark yaratan, alışılmışın dışında içerikler tasarlıyoruz." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1>Dijital Pazarlama <br />Danışmanlığı</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p> Markalarımız için en doğru stratejiyi belirliyor ve dijital teknolojileri en doğru biçimde kullanmalarını sağlıyoruz. </p>
                            <input type="text" className="pink-input" placeholder="E-posta adresinizi girin..." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="social-button" onClick={(e) => this.showModal()}>Size özel teklif alın <img alt="alt text" src={rightArrow}></img></button>
                        </div>
                        <div className="col-md-6" style={{ marginTop: -70, paddingLeft: 50 }}><img alt="alt text" src={digital} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Websitenizi girin." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form>
                                    <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus id="site" required  />
                                    <input type="text" placeholder="Adınızı girin." />
                                    <input type="email" placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container brands_content py60">
                    
                    <div className="studies">
                        <h2 >Kanıtlanmış başarıya sahip ekibimizle dijital pazarlama çalışmalarınıza yön veriyor, 
                        markanızı dijital dünyada <br /> birkaç adım öne çıkarıyoruz.</h2>
                    </div>
                    <h2 class="py60">Referanslarımız</h2>
                     <p>Öne çıkan referans markalar.</p>
                    <div className="companys__social">
                        <Company />
                    </div>
                </div>

                <div className="acquire-bottom social-media-contents" >
                    <div className="seo-services container" style={{background:"#f4f4f4", borderRadius:10 }}>
                        <div className="seo-services_head" style={{marginTop:30, marginBottom:70}}>
                            <h2>Dijital pazarlama danışmanlığı  <br />için neden bizi seçmelisiniz?</h2>
                        </div>
                        <div className="seo-services_content py60" id="seo-audits">
                        
                            <div className="col-md-12 content-list">
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddebir} style={{width:90}}/>
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Size Özel Dijital Pazarlama Stratejisi</h3>
                                    <p style={{fontSize:16}}>Markanızın ihtiyaç ve hedeflerine özel olarak hazırlanan dijital pazarlama yol haritası ile büyümenize hız kazandırırız.</p>
                                </div>
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddeiki} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Entegre Pazarlama İletişimi</h3>
                                    <p style={{fontSize:16}}>Markanızın temsil edilmesi gereken tüm mecralar için iletişim stratejisi kurgulanır, her mecraya özel çözümler üretilir. </p>
                                </div>
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddeuc} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Doğru Teknolojik Temeller</h3>
                                    <p style={{fontSize:16}}>Bilgi teknolojilerinin (IT) her alanına hakim ekibimiz, dijital dünyadaki varlığınızın güçlenmesi için doğru teknolojik altyapılara sahip olmanız için çalışır.</p>
                                </div>
                            
                            </div>

                        </div>
                        <div className="seo-services_content " id="seo-audits" style={{marginTop:-50}}>
                            <div className="col-md-12 content-list">
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddedort} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Uzun Vadeli Sonuçlar</h3>
                                    <p style={{fontSize:16}}>Yerinde saymayan, sürekli gelişen dijital dünyadaki değişimlere hızla adapte olmanızı sağlayarak büyümenizin uzun vadeli olmasına destek oluruz.</p>
                                </div>
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddebes} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Tüm Sürece Hakimiyet</h3>
                                    <p style={{fontSize:16}}>Dijital pazarlama stratejisinin hayata geçirildiği noktadan itibaren tüm plana dahil ve hakim olmanız için düzenli olarak raporlama yapılır.</p>
                                </div>
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddealti} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Uygun Fiyatlandırmalar</h3>
                                    <p style={{fontSize:16}}>Ücret, marka hedefleriniz ve çalışma kapsamına göre belirlenir. Bu doğrultuda aylık bir ön ödeme alınır. Bunun dışında istisnai ücretler talep edilmez.</p>
                                </div>
                            
                            </div>
                        </div>
                    </div>

                </div>

                <Checkup />

                <div className="onboarding container py60" >
                    <div className="col-md-4">
                        <img alt="alt text" src={work} />
                        <h2>İşleyiş <br />sürecimiz.</h2>
                        <p>Markalarımız için dijital danışmanlık sürecimizi nasıl <br />sürdürdüğümüze göz atın.</p>
                    </div>
                    <div className="col-md-8">
                        <div className="hiring-tabs">
                            <input type="checkbox" name="onboarding" id="tab6" />
                            <input type="checkbox" name="onboarding" id="tab7" />
                            <input type="checkbox" name="onboarding" id="tab8" />
                            <input type="checkbox" name="onboarding" id="tab9" />
                            <input type="checkbox" name="onboarding" id="tab10" />
                            <input type="checkbox" name="onboarding" id="tab11" />

                            <div className="tab" id="tab6sec">
                                <label htmlFor="tab6"> </label>
                                <h3> <span>1</span> Keşif görüşmesi yapılır.</h3>
                                <p>- Hedefleriniz, rekabet avantajlarınız, hedef kitleniz, performansınızı etkileyen faktörler gibi markanızı ve hizmet sunduğunuz sektörü olabildiğince iyi tanımak için öncelikle bir görüşme gerçekleştiriyoruz. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab7sec">
                                <label htmlFor="tab7"> </label>
                                <h3> <span>2</span> Hesaplar denetlenerek fırsatlar ortaya çıkarılır.</h3>
                                <p>- Stratejilerimizi oluştururken yararlanacağımızı veriler doğrultusunda mevcut performansınızı analiz ediyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab8sec">
                                <label htmlFor="tab8"> </label>
                                <h3> <span>3</span> Sonraki adımlara geçmek üzere <br />bulgular konuşulur.</h3>
                                <p>- Büyüme fırsatlarını, ayrıntılı iş planını ve yatırım getirisi hesaplamalarını sunmak için yüz yüze veya görüntülü bir konuşma gerçekleştiriyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab9sec">
                                <label htmlFor="tab9"> </label>
                                <h3> <span>4</span> Başlangıç stratejisi oluşturulur ve aksiyona geçilir.</h3>
                                <p>- Audit sonucunda elde edilen bulgulara dayanan ilk 3 aylık öncelikli plana uygun ve dijital dünyada olumlu etkiye sahip olacak eylemlere odaklanıyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab10sec">
                                <label htmlFor="tab10"> </label>
                                <h3> <span>5</span> Optimizasyonlar ve aylık detaylı raporlamalar sürdürülür.</h3>
                                <p>- Performansı gözden geçirmek, gündemi takip etmek ve yeni fikirler üretmek üzere sizinle haftalık olarak görüşüyoruz. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab11sec">
                                <label htmlFor="tab11"> </label>
                                <h3> <span>6</span> Her üç ayda bir gelecek çeyreğe ilişkin <br /> stratejik planlama oturumları gerçekleştirilir. </h3>
                                <p>- Devam eden optimizasyonlar ve stratejik plan masaya yatırılarak sonraki döneme ilişkin ayrıntılı bir analiz değerlendirmesi yapılır, bütçenizin en yüksek değer sunacak şekilde yönetilmesi sağlanır.</p>
                                <div className="plus"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="acquire-bottom social-media-contents" >
                    <div className="seo-services container">
                        <div className="seo-services_head" style={{marginTop:30}}>
                            <h2>Dijital Pazarlama Danışmanlığı Hizmeti <br /> Almanın Başlıca Avantajları</h2>
                        </div>
                        <div className="seo-services_content" id="seo-audits">
                            <div className="col-md-2"> </div>

                            <div className="col-md-8">   
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Büyüme Fırsatlarını Değerlendirme</h3>
                                    <p style={{fontSize:17}}>Hangi ölçekten olursa olsun markanızın dijital dünyadaki varlığının daha fazla güçlenmesi için yeni nesil teknolojilerin sunduğu fırsatlardan yararlanmanızı sağlar.</p>
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Verimliliği Artırma</h3>
                                    <p style={{fontSize:17}}><a href="https://flatart.com.tr/">Dijital pazarlama </a>uzmanlarımız size özel stratejiler hazırlarken değerli zamanınızı ve diğer kaynaklarınızı işletmenizi büyütmek için daha verimli bir şekilde değerlendirebilirsiniz.</p>
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Hedefleri Gerçekleştirme</h3>
                                    <p style={{fontSize:17}}>Dijital pazarlama stratejiniz doğrultusunda atılan tüm adımlar, hedeflerinize daha hızlı bir şekilde ulaşmanızı sağlar.</p>
                                    <p style={{fontSize:17}}>Dijital pazarlama danışmanlığı hizmeti hakkında daha detaylı bilgi edinmek, projeniz hakkında görüşmek veya iş birliği yapmak için <a href="https://flatart.com.tr/iletisim/">Flatart’ın uzman ekibi ile iletişime geçebilirsiniz.</a></p>

                            </div>
                            <div className="col-md-2"> </div>

                        </div>
                       
                    </div>

                </div>

                { /*  <div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Size yardımcı olabilecek <br />uzmanlarımız. </h2>
                    <Teams />
                </div>*/}
                <div className="hiring" style={{ marginTop: 10 }}>
                    <h2>Sıkça Sorulan Sorular</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default SocialMedia
